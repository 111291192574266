/* Courses Page Styles */
.courses-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Courses Header */
.courses-header {
  padding: 4rem 5% 2rem;
  background: linear-gradient(135deg, #f8faff 0%, #f0f7ff 100%);
  text-align: center;
}

.courses-header h1 {
  font-size: 3rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.courses-header p {
  font-size: 1.25rem;
  color: var(--secondary-color);
  max-width: 600px;
  margin: 0 auto;
}

/* Courses Grid */
.courses-grid {
  padding: 4rem 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.course-card {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.course-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.course-icon {
  width: 56px;
  height: 56px;
  background-color: var(--light-gray);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.course-card:hover .course-icon {
  background-color: var(--primary-color);
  color: white;
}

.course-card h2 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.course-description {
  color: var(--secondary-color);
  line-height: 1.6;
}

.course-meta {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.course-meta span {
  background-color: var(--light-gray);
  color: var(--secondary-color);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.course-topics {
  margin-top: 1rem;
}

.course-topics h3 {
  font-size: 1.1rem;
  color: var(--text-color);
  margin-bottom: 0.75rem;
}

.course-topics ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.course-topics li {
  color: var(--secondary-color);
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.course-topics li::before {
  content: "•";
  color: var(--primary-color);
  font-weight: bold;
}

.course-btn {
  margin-top: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.course-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .courses-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    padding: 3rem 5%;
  }
}

@media (max-width: 768px) {
  .courses-header {
    padding: 3rem 5% 1.5rem;
  }

  .courses-header h1 {
    font-size: 2.5rem;
  }

  .courses-header p {
    font-size: 1.1rem;
  }

  .courses-grid {
    grid-template-columns: 1fr;
    padding: 2rem 5%;
  }

  .course-card {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .courses-header h1 {
    font-size: 2rem;
  }

  .courses-header p {
    font-size: 1rem;
  }

  .course-card h2 {
    font-size: 1.25rem;
  }

  .course-meta {
    flex-wrap: wrap;
  }
}

/* Print Styles */
@media print {
  .course-card {
    break-inside: avoid;
    box-shadow: none;
    border: 1px solid var(--border-color);
  }

  .course-btn {
    display: none;
  }
} 