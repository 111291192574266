.chatbox-container {
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  width: 350px;
  height: 500px;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  z-index: 1001;
  overflow: hidden;
}

.chatbox-header {
  padding: 1rem;
  background: var(--primary-color);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbox-title {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.ai-avatar {
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai-avatar svg {
  width: 20px;
  height: 20px;
  stroke: white;
}

.chatbox-info h3 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.status {
  font-size: 0.75rem;
  opacity: 0.8;
}

.close-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.close-button:hover {
  opacity: 0.8;
}

.close-button svg {
  width: 20px;
  height: 20px;
}

.chatbox-messages {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.message {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.message.bot {
  align-self: flex-start;
}

.message.user {
  align-self: flex-end;
}

.message-content {
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  position: relative;
}

.message.bot .message-content {
  background: var(--light-gray);
  color: var(--text-color);
  border-bottom-left-radius: 0.25rem;
}

.message.user .message-content {
  background: var(--primary-color);
  color: white;
  border-bottom-right-radius: 0.25rem;
}

.message-content p {
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.4;
}

.timestamp {
  font-size: 0.7rem;
  opacity: 0.7;
  margin-top: 0.25rem;
  display: block;
}

.chatbox-input {
  padding: 1rem;
  border-top: 1px solid var(--border-color);
  display: flex;
  gap: 0.5rem;
}

.chatbox-input input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  font-size: 0.95rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.chatbox-input input:focus {
  border-color: var(--primary-color);
}

.chatbox-input button {
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.chatbox-input button:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

.chatbox-input button svg {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .chatbox-container {
    bottom: 5rem;
    right: 1rem;
    width: calc(100% - 2rem);
    height: calc(100vh - 6rem);
  }
}

@media (max-width: 480px) {
  .chatbox-container {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
} 