.details-page {
  min-height: 100vh;
  background-color: #f8fafc;
  padding: 2rem 1rem;
}

.details-container {
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  padding: 2rem;
}

.details-header {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e2e8f0;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.profile-icon {
  font-size: 4rem;
  color: #3b82f6;
}

.profile-info h1 {
  font-size: 2rem;
  color: #1e293b;
  margin-bottom: 0.5rem;
}

.rank-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #f1f5f9;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  color: #64748b;
  font-weight: 500;
}

.rank-badge i {
  color: #fbbf24;
}

.score-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.score-card {
  background: #f8fafc;
  border-radius: 0.75rem;
  padding: 1.5rem;
  text-align: center;
}

.score-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
  color: #64748b;
}

.score-header i {
  font-size: 1.5rem;
}

.score-header h3 {
  font-size: 1.1rem;
  font-weight: 500;
}

.score-value {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1e293b;
}

.performance-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.detail-section {
  background: #f8fafc;
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.detail-section h2 {
  color: #1e293b;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e2e8f0;
}

.detail-grid {
  display: grid;
  gap: 1rem;
}

.user-details {
  display: flex;
  gap: 1.5rem;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  font-size: 0.95rem;
  background: #f1f5f9;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.detail-item:hover {
  background: #e2e8f0;
  transform: translateY(-1px);
}

.detail-item i {
  color: #3b82f6;
  font-size: 1rem;
}

.detail-item .label {
  color: #64748b;
  font-weight: 500;
}

.detail-item .value {
  font-weight: 600;
  color: #1e293b;
}

.detail-item .value.completed {
  color: #22c55e;
}

.detail-item .value.incomplete {
  color: #ef4444;
}

.performance-section {
  margin-bottom: 3rem;
}

.performance-section h2 {
  color: #1e293b;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #e2e8f0;
}

.quiz-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.summary-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #f8fafc;
  padding: 1rem;
  border-radius: 0.75rem;
}

.summary-card i {
  font-size: 1.5rem;
  color: #3b82f6;
}

.summary-content h3 {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.25rem;
}

.summary-content p {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
}

.quiz-responses {
  margin-top: 2rem;
}

.quiz-responses h3 {
  color: #1e293b;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.responses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.response-card {
  background: #f8fafc;
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  gap: 1rem;
}

.question-number {
  font-weight: 600;
  color: #3b82f6;
  min-width: 2rem;
}

.question-content {
  flex: 1;
}

.question {
  color: #1e293b;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.answer {
  color: #64748b;
  font-size: 0.875rem;
}

.answer.answered {
  color: #22c55e;
  font-weight: 500;
}

.answer.unanswered {
  color: #ef4444;
  font-style: italic;
}

.coding-question {
  background: #f8fafc;
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.question-header h3 {
  color: #1e293b;
  font-size: 1.25rem;
}

.question-meta {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.time-taken {
  color: #64748b;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.score {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.score.success {
  color: #22c55e;
}

.score.error {
  color: #ef4444;
}

.code-section {
  background: #1e293b;
  border-radius: 0.5rem;
  overflow: hidden;
}

.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  background: #334155;
}

.code-header h4 {
  color: #e2e8f0;
  font-size: 1rem;
}

.copy-btn {
  background: #3b82f6;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
}

.copy-btn:hover {
  background: #2563eb;
}

.code-block {
  margin: 0;
  padding: 1rem;
  overflow-x: auto;
}

.code-block code {
  color: #e2e8f0;
  font-family: 'Fira Code', monospace;
  font-size: 0.875rem;
  line-height: 1.5;
}

.testcase-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background: #fee2e2;
  color: #ef4444;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  gap: 1rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  gap: 1rem;
  text-align: center;
  color: #ef4444;
}

.error-container i {
  font-size: 3rem;
}

.error-container h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.error-container p {
  color: #64748b;
}

.testcases-section {
  margin-top: 2rem;
}

.testcases-section h4 {
  color: #1e293b;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.testcases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.testcase-card {
  background: white;
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.testcase-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.testcase-number {
  font-weight: 600;
  color: #3b82f6;
}

.testcase-status {
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.testcase-status.success {
  background: #dcfce7;
  color: #16a34a;
}

.testcase-status.error {
  background: #fee2e2;
  color: #dc2626;
}

.testcase-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.testcase-input,
.testcase-output {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.testcase-input .label,
.testcase-output .label {
  font-size: 0.875rem;
  color: #64748b;
  font-weight: 500;
}

.testcase-input .value,
.testcase-output .value {
  font-family: 'Fira Code', monospace;
  font-size: 0.875rem;
  color: #1e293b;
  background: #f8fafc;
  padding: 0.5rem;
  border-radius: 0.375rem;
  white-space: pre-wrap;
  word-break: break-all;
}

@media (max-width: 768px) {
  .details-container {
    padding: 1rem;
  }

  .user-profile {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
  }

  .score-overview {
    grid-template-columns: 1fr;
  }

  .performance-details {
    grid-template-columns: 1fr;
  }

  .question-header {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .question-meta {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .code-header {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .copy-btn {
    width: 100%;
    justify-content: center;
  }

  .testcases-grid {
    grid-template-columns: 1fr;
  }

  .testcase-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .testcase-status {
    width: 100%;
    text-align: center;
  }

  .user-details {
    flex-direction: column;
    gap: 0.75rem;
  }

  .detail-item {
    width: 100%;
    justify-content: center;
  }
}
