/* CodingCompetition.css */
.coding-container {
    background: #f5f7fa;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    position: relative;
}

.coding-header {
    background: #ffffff;
    border-bottom: 1px solid #e2e8f0;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
}

.coding-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a202c;
}

.coding-timer {
    font-size: 1rem;
    font-weight: 500;
    color: #4a5568;
    background: #edf2f7;
    padding: 0.5rem 1rem;
    border-radius: 6px;
}

.coding-main {
    display: flex;
    flex-grow: 1;
    margin-top: 4.5rem;
    padding: 0;
    overflow: hidden;
    gap: 1rem;
}

.coding-question-panel {
    flex: 1;
    background: #ffffff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-right: 1px solid #e2e8f0;
    overflow-y: auto;
}

.coding-question-header {
    margin-bottom: 1.5rem;
}

.coding-question-number {
    font-size: 0.9rem;
    color: #718096;
    font-weight: 500;
}

.coding-question-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.coding-question-content::-webkit-scrollbar {
    display: none;
}

.coding-question-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a202c;
    margin-bottom: 1rem;
    white-space: normal;
}

.coding-question-description {
    font-size: 1rem;
    color: #4a5568;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    white-space: normal;
}

.coding-sample {
    font-size: 0.95rem;
    color: #4a5568;
    background: #f7fafc;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
    white-space: normal;
}

.coding-sample p {
    margin: 0.5rem 0;
}

.coding-navigation-buttons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1.5rem;
}

.coding-prev-button, .coding-next-button {
    background: #3182ce;
    color: #ffffff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s ease;
}

.coding-prev-button:disabled {
    background: #a0aec0;
    cursor: not-allowed;
}

.coding-prev-button:hover:not(:disabled), .coding-next-button:hover {
    background: #2b6cb0;
}

.coding-editor-container {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    height: calc(100vh - 4.5rem); /* Full height minus header */
    min-width: 70%;
    max-width: 600px;
    padding: 1rem;
    box-sizing: border-box;
    overflow-y: auto; /* Allow scrolling for entire container */
}

.editor-wrapper {
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background: #ffffff;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto; /* Fixed size, no stretching */
}

.coding-language-selector {
    padding: 0.5rem 1rem;
    background: #f7fafc;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    justify-content: flex-end;
}

.coding-language-dropdown {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #1a202c;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s ease;
    width: 150px;
}

.coding-language-dropdown:hover {
    border-color: #3182ce;
}

.coding-editor {
    flex-grow: 1;
    border: none;
    border-radius: 0 0 6px 6px;
    background: #ffffff;
    min-height: 400px; /* Fixed minimum height for editor */
    width: 100%;
}

.ace_gutter {
    border-right: none !important;
    background: #f7f7f7;
}

.coding-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    flex-shrink: 0; /* Prevent shrinking */
}

.coding-run-button, .coding-submit-button {
    background: #3182ce;
    color: #ffffff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s ease;
}

.coding-run-button:hover, .coding-submit-button:hover {
    background: #2b6cb0;
}

.coding-submit-button {
    background-color: #28a745;
}

.coding-submit-button:hover {
    background-color: #218838;
}

.coding-output {
    font-size: 0.95rem;
    color: #1a202c;
    margin-top: 1rem;
    flex-shrink: 0; /* Prevent shrinking */
}

.coding-output pre {
    margin: 0;
    white-space: pre-wrap;
    background: #f7fafc;
    padding: 0.5rem;
    border-radius: 4px;
    color: #000000;
    background-color: #dfdfdf;
    max-height: 150px; /* Scrollable if too long */
    overflow-y: auto;
}

.coding-test-results {
    font-size: 0.95rem;
    color: #1a202c;
    margin-top: 1rem;
    flex-shrink: 0; /* Prevent shrinking */
    max-height: 150px; /* Scrollable if too long */
    overflow-y: auto;
}

.coding-test-results ul {
    list-style: none;
    padding: 0;
}

.coding-test-results li {
    margin-bottom: 0.5rem;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 0.9rem;
}

.coding-test-results .passed {
    color: #38a169;
}

.coding-test-results .failed {
    color: #e53e3e;
}

.coding-message {
    text-align: center;
    color: #1a202c;
    font-size: 1.5rem;
    padding: 2rem;
    font-weight: 500;
}

.coding-watermark {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 1rem;
    color: rgba(44, 96, 173, 0.3);
    font-weight: 400;
    pointer-events: none;
    z-index: 10;
}

.coding-question-level {
    font-size: 1.2rem;
    color: #666;
    margin-left: 1rem;
}

/* Desktop content (visible by default) */
.desktop-content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

/* Mobile message (hidden by default) */
.mobile-message {
    display: none;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    color: #333;
    background-color: #f8d7da;
    padding: 1rem;
}

/* Popup Notification Styles */
.popup-notification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Above all other content */
  }
  
  .popup-content {
    background: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    text-align: center;
    font-size: 1.2rem;
    color: #1a202c;
    font-weight: 500;
    line-height: 1.5;
  }

  .confirm-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it’s above other content */
  }
  
  .confirm-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .confirm-modal p {
    margin: 0 0 20px;
    font-size: 16px;
  }
  
  .confirm-modal-buttons {
    display: flex;
    justify-content: space-around;
  }
  
  .confirm-button,
  .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .confirm-button {
    background-color: #4caf50; /* Green */
    color: white;
  }
  
  .confirm-button:hover {
    background-color: #45a049;
  }
  
  .cancel-button {
    background-color: #f44336; /* Red */
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #da190b;
  }



/* Media query for mobile devices (screen width <= 768px) */
@media (max-width: 768px) {
    .desktop-content {
        display: none;
    }
    .mobile-message {
        display: flex;
    }

    .coding-header {
        padding: 1rem;
        flex-direction: column;
        gap: 0.5rem;
    }

    .coding-main {
        flex-direction: column;
        margin-top: 4rem;
        gap: 0.5rem;
    }

    .coding-question-panel, .coding-editor-container {
        flex: none;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #e2e8f0;
        height: auto;
    }

    .coding-editor {
        height: 300px;
    }

    .editor-wrapper {
        height: 350px;
    }

    .coding-actions {
        flex-direction: column;
        gap: 0.5rem;
    }

    .coding-run-button, .coding-submit-button {
        width: 100%;
    }

    .coding-navigation-buttons {
        flex-direction: column;
        gap: 0.5rem;
    }

    .coding-prev-button, .coding-next-button {
        width: 100%;
    }

    .coding-language-selector {
        justify-content: center;
    }

    .coding-watermark {
        font-size: 0.7rem;
        bottom: 0.5rem;
        right: 0.5rem;
    }
}