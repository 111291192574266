/* Footer.css */
.practcode-footer {
    background: #1a1a1a;
    color: #ffffff;
    padding: 40px 0;
    font-family: 'Arial', sans-serif;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-section {
    margin: 0 15px;
}

.footer-section h3 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #ffffff;
}

.footer-section h4 {
    font-size: 16px;
    margin-bottom: 15px;
    color: #ffffff;
}

.footer-section p {
    color: #b3b3b3;
    line-height: 1.6;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section a {
    color: #b3b3b3;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section a:hover {
    color: #ffffff;
}

.social-links a {
    margin-right: 15px;
}

.footer-bottom {
    text-align: center;
    padding-top: 20px;
    color: #b3b3b3;
    font-size: 14px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
        text-align: center;
    }
    
    .social-links a {
        margin: 0 7px;
    }
}