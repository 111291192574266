/* Modern Light Theme Variables */
:root {
  --primary-color: #2563eb;
  --primary-light: #3b82f6;
  --primary-dark: #1d4ed8;
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --background-light: #ffffff;
  --background-dark: #f8faff;
  --border-color: #e2e8f0;
  --success-color: #22c55e;
  --error-color: #ef4444;
  --gold-color: #fbbf24;
  --silver-color: #94a3b8;
  --bronze-color: #b45309;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  --transition: all 0.2s ease;
}

/* Base Styles */
.leaderboard-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #f8faff 0%, #ffffff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.leaderboard-container {
  width: 100%;
  max-width: 1200px;
  background: var(--background-light);
  border-radius: 1rem;
  box-shadow: var(--shadow-lg);
  overflow: hidden;
  margin: 0 auto;
  border: 1px solid var(--border-color);
  padding: 2.5rem;
}

/* Header Styles */
.leaderboard-header {
  text-align: center;
  margin-bottom: 3rem;
}

.leaderboard-header h1 {
  font-size: 2.25rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  letter-spacing: -0.5px;
}

.leaderboard-header p {
  font-size: 1.125rem;
  color: var(--text-secondary);
}

/* Statistics Section */
.statistics-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.stat-card {
  background: var(--background-light);
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  box-shadow: var(--shadow-md);
  border: 1px solid var(--border-color);
  transition: var(--transition);
}

.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-lg);
}

.stat-icon {
  width: 48px;
  height: 48px;
  background: var(--primary-light);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
}

.stat-content h3 {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-secondary);
  margin-bottom: 0.25rem;
}

.stat-content p {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.25rem;
}

.stat-content .percentage {
  font-size: 0.875rem;
  color: var(--success-color);
  font-weight: 500;
}

/* Leaderboard Table */
.leaderboard-table {
  overflow-x: auto;
  margin-top: 2rem;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 1rem;
}

th {
  background: var(--background-dark);
  color: var(--text-secondary);
  font-weight: 600;
  text-align: left;
  padding: 1rem;
  font-size: 0.875rem;
  border-bottom: 2px solid var(--border-color);
}

td {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
  font-size: 0.875rem;
}

tr:hover {
  background: var(--background-dark);
}

/* Rank Badge */
.rank-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  color: var(--text-primary);
}

.rank-badge i {
  font-size: 1.25rem;
}

/* Top 3 Rows */
.top-1 {
  background: rgba(251, 191, 36, 0.1);
}

.top-1 .rank-badge i {
  color: var(--gold-color);
}

.top-2 {
  background: rgba(148, 163, 184, 0.1);
}

.top-2 .rank-badge i {
  color: var(--silver-color);
}

.top-3 {
  background: rgba(180, 83, 9, 0.1);
}

.top-3 .rank-badge i {
  color: var(--bronze-color);
}

/* Loading State */
.loading-container {
  text-align: center;
  padding: 2rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--border-color);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error State */
.error-container {
  text-align: center;
  padding: 2rem;
  color: var(--error-color);
}

.error-container i {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.error-container h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .leaderboard-container {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .leaderboard-page {
    padding: 1.5rem;
  }

  .leaderboard-container {
    padding: 1.5rem;
  }

  .leaderboard-header h1 {
    font-size: 2rem;
  }

  .statistics-section {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .leaderboard-page {
    padding: 1rem;
  }

  .leaderboard-container {
    padding: 1rem;
  }

  .leaderboard-header h1 {
    font-size: 1.75rem;
  }

  th, td {
    padding: 0.75rem;
    font-size: 0.75rem;
  }
}

/* Print Styles */
@media print {
  .leaderboard-container {
    box-shadow: none;
    border: none;
  }

  .stat-card {
    break-inside: avoid;
  }

  table {
    break-inside: auto;
  }
}

.username-link {
  color: #3b82f6;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.username-link:hover {
  color: #2563eb;
  text-decoration: underline;
}
