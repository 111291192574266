.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: white;
  box-shadow: var(--shadow-sm);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav-brand {
  display: flex;
  align-items: center;
}

.brand-link {
  text-decoration: none;
}

.nav-brand h1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.brand-ai {
  color: var(--primary-color);
  font-weight: 800;
  font-size: 1.8rem;
}

.mobile-menu-btn {
  display: none;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s ease;
}

.mobile-menu-btn:hover {
  color: var(--primary-color);
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: var(--text-secondary);
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  position: relative;
}

.nav-link:hover {
  color: var(--primary-color);
}

.nav-link.active {
  color: var(--primary-color);
  background-color: var(--light-gray);
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.auth-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.primary-btn {
  background-color: var(--primary-color);
  color: white !important;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.primary-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.primary-btn.active {
  background-color: var(--primary-dark);
}

@media (max-width: 1024px) {
  .nav-links {
    gap: 1.5rem;
  }

  .nav-link {
    padding: 0.5rem 0.75rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem 3%;
  }

  .mobile-menu-btn {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 72px; /* Height of navbar */
    left: 0;
    right: 0;
    background-color: white;
    flex-direction: column;
    padding: 1rem;
    gap: 0.5rem;
    box-shadow: var(--shadow-md);
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }

  .nav-links.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .nav-link {
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }

  .nav-link.active::after {
    display: none;
  }

  .auth-buttons {
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .auth-buttons .nav-link {
    text-align: center;
  }

  .primary-btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0.75rem 1rem;
  }

  .nav-brand h1 {
    font-size: 1.5rem;
  }

  .brand-ai {
    font-size: 1.5rem;
  }

  .nav-links {
    top: 64px; /* Adjusted for smaller navbar height */
  }
} 