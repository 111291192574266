.blog-post {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 5%;
}

.blog-post-header {
  margin-bottom: 2rem;
}

.blog-post-meta {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
  color: var(--secondary-color);
  font-size: 0.875rem;
}

.blog-post-meta span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.blog-post-category {
  background: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-weight: 500;
}

.blog-post-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-color);
  line-height: 1.2;
  margin-bottom: 1rem;
}

.blog-post-author {
  color: var(--secondary-color);
  font-size: 1rem;
}

.blog-post-image {
  margin: 2rem 0;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: var(--shadow-lg);
}

.blog-post-image img {
  width: 100%;
  height: auto;
  display: block;
}

.blog-post-content {
  font-size: 1.125rem;
  line-height: 1.8;
  color: var(--text-color);
}

.blog-post-content h2 {
  font-size: 1.75rem;
  font-weight: 600;
  margin: 2rem 0 1rem;
  color: var(--text-color);
}

.blog-post-content p {
  margin-bottom: 1.5rem;
}

.blog-post-content ul,
.blog-post-content ol {
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}

.blog-post-content li {
  margin-bottom: 0.75rem;
}

.blog-post-footer {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
}

.back-to-blog {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.back-to-blog:hover {
  gap: 0.75rem;
}

.back-to-blog .arrow-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.back-to-blog:hover .arrow-icon {
  transform: rotate(180deg) translateX(4px);
}

@media (max-width: 768px) {
  .blog-post {
    padding: 1.5rem 5%;
  }

  .blog-post-title {
    font-size: 2rem;
  }

  .blog-post-meta {
    flex-wrap: wrap;
  }

  .blog-post-content {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .blog-post-title {
    font-size: 1.75rem;
  }

  .blog-post-meta {
    flex-direction: column;
    gap: 0.75rem;
  }
} 