.top-users-container {
  padding: 2rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;
}

.top-users-container h2 {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.top-users-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  align-items: center;
}

.top-user-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  background: var(--light-gray);
  border-radius: 1rem;
  transition: transform 0.3s ease;
}

.top-user-card:hover {
  transform: translateY(-5px);
}

.user-rank {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.user-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
  border: 3px solid white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-info {
  text-align: center;
}

.user-info h3 {
  margin: 0;
  color: var(--text-color);
  font-size: 1.1rem;
  font-weight: 600;
}

.user-info p {
  margin: 0.5rem 0 0;
  color: var(--primary-color);
  font-weight: 500;
}

@media (max-width: 768px) {
  .top-users-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .top-user-card {
    flex-direction: row;
    padding: 1rem;
    gap: 1rem;
  }

  .user-rank {
    position: static;
    transform: none;
    width: 25px;
    height: 25px;
  }

  .user-avatar {
    width: 60px;
    height: 60px;
  }

  .user-info {
    text-align: left;
    flex: 1;
  }
} 