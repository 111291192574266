/* QuizPage.css */
.quiz-container {
    background: #f4f6f8;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
    position: relative;
}

.quiz-header {
    background: #ffffff;
    border-bottom: 2px solid #e0e4e8;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
}

.quiz-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a202c;
}

.quiz-timer {
    font-size: 1.1rem;
    font-weight: 500;
    color: #4a5568;
    background: #edf2f7;
    padding: 0.5rem 1rem;
    border-radius: 6px;
}

.quiz-main {
    display: flex;
    flex-grow: 1;
    margin-top: 5rem;
    padding: 0;
    overflow-y: auto;
    align-items: stretch; /* Ensure children stretch to full height */
    gap: 1rem;
    height: calc(100vh - 5rem); /* Ensure it takes full height minus header */
}

.quiz-content {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    width: 100%;
}

.quiz-question-container {
    background: #ffffff;
    border-radius: 0;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid #e2e8f0;
    width: 100%;
    margin-top: 0;
    box-sizing: border-box;
    height: 100%; /* Match the height of quiz-navigation */
    overflow-x: auto; /* Horizontal scrolling for large content */
    overflow-y: auto; /* Vertical scrolling if needed */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}

.quiz-question-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, Edge */
}

.quiz-question-number {
    font-size: 0.9rem;
    color: #718096;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.quiz-question {
    color: #1a202c;
    font-size: 1.5rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    word-wrap: break-word;
    font-weight: 500;
}

.quiz-code {
    background: #f7fafc;
    padding: 1rem;
    border-radius: 6px;
    font-family: 'Source Code Pro', monospace;
    font-size: 0.95rem;
    line-height: 1.5;
    white-space: pre-wrap;
    overflow-x: auto;
    margin-bottom: 1.5rem;
    border: 1px solid #e2e8f0;
}

.quiz-options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
}

.quiz-option {
    background: #ffffff;
    border: 2px solid #e2e8f0;
    padding: 1rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    text-align: left;
    transition: all 0.3s ease;
    word-wrap: break-word;
    overflow-wrap: anywhere;
}

.quiz-option:hover {
    background: #f7fafc;
    border-color: #3182ce;
}

.quiz-option.selected {
    background: #3182ce;
    color: #ffffff;
    border-color: #3182ce;
}

.quiz-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1rem;
}

.quiz-button, .quiz-skip-button {
    background: #3182ce;
    color: #ffffff;
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.3s ease;
}

.quiz-skip-button {
    background: #a0aec0;
}

.quiz-button:hover {
    background: #2b6cb0;
}

.quiz-skip-button:hover {
    background: #718096;
}

.quiz-button:disabled {
    background: #a0aec0;
    cursor: not-allowed;
}

.quiz-message {
    text-align: center;
    color: #1a202c;
    font-size: 1.5rem;
    padding: 2rem;
    font-weight: 500;
}

.quiz-navigation {
    flex: 1;
    padding: 2rem;
    background: #ffffff;
    border-left: 1px solid #e2e8f0;
    overflow-y: auto;
    margin-top: 0;
    width: 100%;
    box-sizing: border-box;
}

.quiz-navigation h3 {
    margin: 0 0 1rem 0;
    font-size: 1.25rem;
    color: #1a202c;
    font-weight: 600;
}

.quiz-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 questions per row */
    gap: 0.5rem;
}

.quiz-grid-item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #e2e8f0;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    color: #1a202c;
    background: #ffffff;
    transition: all 0.3s ease;
}

.quiz-grid-item:hover {
    background: #f7fafc;
}

.quiz-grid-item.current {
    border-color: #3182ce;
    background: #e6f0fa;
    color: #3182ce;
}

.quiz-grid-item.submitted {
    background: #38a169;
    border-color: #38a169;
    color: #ffffff;
}

.quiz-grid-item.skipped {
    background: #ecc94b;
    border-color: #ecc94b;
    color: #1a202c;
}

.quiz-watermark {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 1rem;
    color: rgba(34, 88, 170, 0.3);
    font-weight: 400;
    pointer-events: none;
    z-index: 10;
}
.popup-notification {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    text-align: center;
    font-size: 16px;
  }
/* Mobile adjustments */
@media (max-width: 768px) {
    .quiz-header {
        padding: 1rem;
        flex-direction: column;
        gap: 0.5rem;
    }

    .quiz-main {
        flex-direction: column;
        padding: 0;
        margin-top: 4rem;
        gap: 0.5rem;
    }

    .quiz-content {
        flex: none;
        width: 100%;
    }

    .quiz-question-container {
        padding: 1rem;
        border-radius: 0;
    }

    .quiz-question {
        font-size: 1.25rem;
    }

    .quiz-option {
        padding: 0.75rem 1rem;
    }

    .quiz-actions {
        flex-direction: column;
        gap: 0.5rem;
    }

    .quiz-button, .quiz-skip-button {
        width: 100%;
    }

    .quiz-navigation {
        flex: none;
        border-left: none;
        border-top: 1px solid #e2e8f0;
        padding: 1rem;
        width: 100%;
    }

    .quiz-grid-item {
        width: 35px;
        height: 35px;
        font-size: 0.9rem;
    }

    .quiz-question-number {
        font-size: 0.8rem;
    }

    .quiz-watermark {
        font-size: 0.7rem;
        bottom: 0.5rem;
        right: 0.5rem;
    }
}