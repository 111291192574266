/* Modern Light Theme Variables */
:root {
  --primary-color: #2563eb;
  --primary-light: #3b82f6;
  --primary-dark: #1d4ed8;
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --background-light: #ffffff;
  --background-dark: #f8faff;
  --border-color: #e2e8f0;
  --success-color: #22c55e;
  --error-color: #ef4444;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  --transition: all 0.2s ease;
}

/* Base Styles */
.result-page {
  min-height: 100vh;
  background: linear-gradient(135deg, #f8faff 0%, #ffffff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.result-container {
  width: 100%;
  max-width: 1200px;
  background: var(--background-light);
  border-radius: 1rem;
  box-shadow: var(--shadow-lg);
  overflow: hidden;
  margin: 0 auto;
  border: 1px solid var(--border-color);
  padding: 2.5rem;
}

/* Header Styles */
.result-header {
  text-align: center;
  margin-bottom: 3rem;
}

.result-header h1 {
  font-size: 2.25rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  letter-spacing: -0.5px;
}

.result-header p {
  font-size: 1.125rem;
  color: var(--text-secondary);
}

/* Score Section */
.score-section {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 4rem;
}

.score-card {
  flex: 1;
  max-width: 400px;
  background: var(--background-light);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  box-shadow: var(--shadow-md);
  border: 1px solid var(--border-color);
}

.score-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  margin-bottom: 2rem;
}

.score-header i {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.score-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

/* Score Circle */
.score-circle {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.score-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}

.score-value {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-color);
  line-height: 1;
  margin-bottom: 0.5rem;
}

.score-label {
  font-size: 1rem;
  color: var(--text-secondary);
  font-weight: 500;
}

.score-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.score-progress svg {
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
}

.score-progress path {
  transition: var(--transition);
}

/* Details Section */
.details-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.details-card {
  background: var(--background-light);
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: var(--shadow-md);
  border: 1px solid var(--border-color);
}

.details-card h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.result-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.detail-card {
  background: var(--background-dark);
  border-radius: 0.75rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: var(--transition);
}

.detail-card:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.detail-icon {
  width: 48px;
  height: 48px;
  background: var(--primary-light);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
}

.detail-content h3 {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-secondary);
  margin-bottom: 0.25rem;
}

.detail-content p {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
}

.result-summary {
  background: var(--background-dark);
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.result-summary h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.summary-content {
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Loading State */
.loading-container {
  text-align: center;
  padding: 2rem;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--border-color);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error State */
.error-container {
  text-align: center;
  padding: 2rem;
  color: var(--error-color);
}

.error-container i {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.error-container h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .score-section {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .score-card {
    width: 100%;
    max-width: 500px;
  }

  .details-section {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .result-page {
    padding: 1.5rem;
  }

  .result-container {
    padding: 2rem;
  }

  .score-circle {
    width: 180px;
    height: 180px;
  }

  .score-value {
    font-size: 2.5rem;
  }

  .result-header h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .result-page {
    padding: 1rem;
  }

  .result-container {
    padding: 1.5rem;
  }

  .score-circle {
    width: 160px;
    height: 160px;
  }

  .score-value {
    font-size: 2rem;
  }

  .result-header h1 {
    font-size: 1.75rem;
  }

  .detail-card {
    padding: 1.25rem;
  }
}

/* Print Styles */
@media print {
  .result-container {
    box-shadow: none;
    border: none;
  }

  .score-circle {
    border: 1px solid var(--border-color);
    border-radius: 50%;
  }
}
