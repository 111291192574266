/* Base Styles */
:root {
  --primary-color: #2563eb;
  --primary-dark: #1d4ed8;
  --secondary-color: #64748b;
  --background-color: #ffffff;
  --text-color: #1e293b;
  --light-gray: #f1f5f9;
  --border-color: #e2e8f0;
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.home-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Navigation */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: var(--background-color);
  box-shadow: var(--shadow-sm);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav-brand h1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.brand-ai {
  color: var(--primary-color);
  font-weight: 800;
  font-size: 1.8rem;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: var(--primary-color);
}

/* Buttons */
.primary-btn {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.primary-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: var(--shadow-md);
}

.secondary-btn {
  background-color: var(--light-gray);
  color: var(--text-color);
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.secondary-btn:hover {
  background-color: var(--border-color);
  transform: translateY(-2px);
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, #f8faff 0%, #f0f7ff 100%);
  padding: 6rem 5% 4rem;
  position: relative;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%232563eb' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.5;
}

.hero-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  position: relative;
  z-index: 1;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.hero-text h1 {
  font-size: 3.5rem;
  line-height: 1.2;
  color: var(--text-color);
  font-weight: 800;
}

.hero-text h1 .highlight {
  color: var(--primary-color);
  position: relative;
  display: inline-block;
}

.hero-text h1 .highlight::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: var(--primary-color);
  opacity: 0.2;
  border-radius: 4px;
}

.hero-text p {
  font-size: 1.25rem;
  line-height: 1.6;
  color: var(--text-secondary);
  max-width: 500px;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.hero-buttons .primary-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 0.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.hero-buttons .primary-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.hero-buttons .secondary-btn {
  padding: 1rem 2rem;
  background-color: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 0.5rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.hero-buttons .secondary-btn:hover {
  background-color: var(--primary-color);
  color: white;
  transform: translateY(-2px);
}

.hero-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.stat-card {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: var(--shadow-md);
  text-align: center;
  transition: all 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.stat-card h3 {
  font-size: 2.5rem;
  color: var(--primary-color);
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.stat-card p {
  color: var(--text-secondary);
  font-size: 1.1rem;
  font-weight: 500;
}

/* Features Section */
.features-section {
  padding: 5rem 5%;
  background-color: white;
}

.features-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  padding: 2rem;
  background: white;
  border-radius: 1rem;
  box-shadow: var(--shadow-md);
  text-align: center;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  width: 64px;
  height: 64px;
  background-color: var(--light-gray);
  border-radius: 16px;
  transition: all 0.3s ease;
}

.feature-card:hover .feature-icon {
  background-color: var(--primary-color);
  transform: translateY(-5px);
}

.feature-card:hover .feature-icon svg {
  stroke: white;
}

.feature-card h3 {
  margin-bottom: 1rem;
  color: var(--text-color);
}

.feature-card p {
  color: var(--secondary-color);
}

/* Competition Banner */
.competition-banner {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--primary-dark) 100%);
  color: white;
  padding: 4rem 5%;
  text-align: center;
}

.banner-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.competition-status {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #fef3c7;
  background-color: rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
}

.competition-details {
  margin-bottom: 2rem;
  opacity: 0.9;
}

.banner-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.banner-buttons .secondary-btn {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.banner-buttons .secondary-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
}

/* Footer */
.footer {
  background-color: var(--light-gray);
  padding: 4rem 5% 2rem;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section h3 {
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.footer-section a {
  display: block;
  color: var(--secondary-color);
  text-decoration: none;
  margin-bottom: 0.75rem;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: var(--primary-color);
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-link {
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: var(--primary-color);
}

.footer-bottom {
  text-align: center;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid var(--border-color);
  color: var(--secondary-color);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .hero-content {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 3rem;
  }

  .hero-text {
    align-items: center;
  }

  .hero-text p {
    margin: 0 auto;
  }

  .hero-buttons {
    justify-content: center;
  }

  .hero-stats {
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .nav-brand h1 {
    font-size: 1.5rem;
  }

  .hero-section {
    padding: 4rem 5% 3rem;
  }

  .hero-text h1 {
    font-size: 2.5rem;
  }

  .hero-text p {
    font-size: 1.1rem;
  }

  .hero-stats {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .stat-card {
    padding: 1.5rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }

  .banner-buttons {
    flex-direction: column;
  }
  
  .banner-buttons .primary-btn,
  .banner-buttons .secondary-btn {
    width: 100%;
    text-align: center;
  }

  .mobile-bottom-nav {
    display: flex;
    justify-content: space-around;
  }

  .footer {
    padding-bottom: 80px;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 3rem 5% 2rem;
  }

  .hero-text h1 {
    font-size: 2rem;
  }

  .hero-buttons {
    flex-direction: column;
  }

  .hero-buttons .primary-btn,
  .hero-buttons .secondary-btn {
    width: 100%;
    justify-content: center;
  }

  .stat-card {
    padding: 1.5rem;
  }

  .stat-card h3 {
    font-size: 2rem;
  }

  .banner-content h2 {
    font-size: 2rem;
  }

  .features-section h2 {
    font-size: 2rem;
  }
}

/* Print Styles */
@media print {
  .navbar {
    box-shadow: none;
  }

  .hero-section {
    background: none;
  }

  .stat-card,
  .feature-card {
    box-shadow: none;
    border: 1px solid var(--border-color);
  }

  .competition-banner {
    background: none;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }

  .footer {
    background: none;
    border-top: 1px solid var(--border-color);
  }
}

/* Main Content */
.main-content {
  flex: 1;
  padding-bottom: 70px; /* Space for mobile bottom nav */
}

/* Mobile Bottom Navigation */
.mobile-bottom-nav {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 0.5rem 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-top: 1px solid var(--border-color);
}

.mobile-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 0.75rem;
  flex: 1;
  padding: 0.5rem;
  transition: all 0.3s ease;
}

.mobile-nav-item.active {
  color: var(--primary-color);
}

.mobile-nav-item.active svg {
  stroke: var(--primary-color);
}

.mobile-nav-item svg {
  margin-bottom: 0.25rem;
  transition: stroke 0.3s ease;
}

.mobile-nav-label {
  font-size: 0.75rem;
  font-weight: 500;
}

/* Show mobile bottom nav only on mobile devices */
@media (max-width: 768px) {
  .mobile-bottom-nav {
    display: flex;
    justify-content: space-around;
  }

  /* Adjust footer padding to account for bottom nav */
  .footer {
    padding-bottom: 80px;
  }
}

/* Blog Section */
.blog-section {
  padding: 6rem 5%;
  background-color: var(--background-color);
  position: relative;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.section-header h2 {
  font-size: 2.5rem;
  color: var(--text-color);
  position: relative;
  padding-bottom: 1rem;
}

.section-header h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: var(--primary-color);
  border-radius: 2px;
}

.view-all {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.view-all:hover {
  transform: translateX(5px);
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.blog-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.blog-image-wrapper {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-image {
  transform: scale(1.05);
}

.blog-overlay {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.blog-category {
  background: rgba(37, 99, 235, 0.9);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  backdrop-filter: blur(4px);
}

.blog-content {
  padding: 1.5rem;
}

.blog-meta {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
  color: var(--secondary-color);
  font-size: 0.875rem;
}

.blog-meta span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.blog-meta .icon {
  color: var(--secondary-color);
}

.blog-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 0.75rem;
  line-height: 1.4;
}

.blog-excerpt {
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.read-more {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.read-more:hover {
  gap: 0.75rem;
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.read-more:hover .arrow-icon {
  transform: translateX(4px);
}

@media (max-width: 768px) {
  .blog-section {
    padding: 4rem 5%;
  }

  .section-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .section-header h2 {
    font-size: 2rem;
  }

  .blog-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .blog-section {
    padding: 3rem 5%;
  }

  .section-header h2 {
    font-size: 1.75rem;
  }

  .blog-title {
    font-size: 1.1rem;
  }

  .blog-content {
    padding: 1.25rem;
  }
}

/* Courses Section */
.courses-section {
  padding: 6rem 5%;
  background-color: var(--light-gray);
}

.courses-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.course-card {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: var(--shadow-md);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.course-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.course-icon {
  width: 48px;
  height: 48px;
  background-color: var(--light-gray);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  transition: all 0.3s ease;
}

.course-card:hover .course-icon {
  background-color: var(--primary-color);
  color: white;
}

.course-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-color);
}

.course-card p {
  color: var(--secondary-color);
  font-size: 0.95rem;
  line-height: 1.5;
}

.course-meta {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
}

.course-meta span {
  background-color: var(--light-gray);
  color: var(--secondary-color);
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.course-btn {
  margin-top: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.course-btn:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .courses-section {
    padding: 4rem 5%;
  }

  .courses-grid {
    grid-template-columns: 1fr;
  }

  .course-card {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .courses-section {
    padding: 3rem 5%;
  }

  .course-card h3 {
    font-size: 1.1rem;
  }

  .course-meta {
    flex-wrap: wrap;
  }
}

/* AI Chat Support Button */
.ai-chat-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background-color: var(--primary-color);
  color: white;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 3rem;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  z-index: 1000;
}

.ai-chat-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  background-color: var(--primary-dark);
}

.ai-chat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.ai-chat-label {
  font-weight: 600;
  font-size: 1rem;
}

/* Adjust position when mobile bottom nav is present */
@media (max-width: 768px) {
  .ai-chat-button {
    bottom: 5rem; /* Account for mobile bottom navigation */
    right: 1rem;
    padding: 0.75rem 1.25rem;
  }

  .ai-chat-icon {
    width: 20px;
    height: 20px;
  }

  .ai-chat-label {
    font-size: 0.9rem;
  }
}

/* Compact version for very small screens */
@media (max-width: 480px) {
  .ai-chat-button {
    padding: 0.75rem;
  }

  .ai-chat-label {
    display: none;
  }

  .ai-chat-icon {
    margin: 0;
  }
} 