/* Modern Light Theme Variables */
:root {
    --primary-color: #2563eb;
    --primary-light: #3b82f6;
    --primary-dark: #1d4ed8;
    --text-primary: #1e293b;
    --text-secondary: #64748b;
    --background-light: #ffffff;
    --background-dark: #f8faff;
    --border-color: #e2e8f0;
    --error-color: #ef4444;
    --success-color: #22c55e;
    --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    --transition: all 0.2s ease;
  }
  
  /* Base Styles */
  .signup-page {
    min-height: 100vh;
    background: linear-gradient(135deg, #f8faff 0%, #ffffff 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  }
  
  .signup-container {
    width: 100%;
    max-width: 600px;
    background: var(--background-light);
    border-radius: 1rem;
    box-shadow: var(--shadow-lg);
    overflow: hidden;
    margin: 0 auto;
    border: 1px solid var(--border-color);
  }
  
  .signup-content {
    padding: 2.5rem;
  }
  
  /* Header Styles */
  .signup-header {
    text-align: center;
    margin-bottom: 2.5rem;
  }
  
  .logo {
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    letter-spacing: -0.5px;
  }
  
  .logo-text {
    color: var(--primary-color);
  }
  
  .logo-accent {
    color: var(--primary-dark);
  }
  
  .signup-header h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--text-primary);
    margin-bottom: 0.75rem;
    letter-spacing: -0.5px;
  }
  
  .signup-header p {
    font-size: 1rem;
    color: var(--text-secondary);
    max-width: 400px;
    margin: 0 auto;
  }
  
  /* Form Container */
  .signup-form-container {
    margin-top: 2rem;
  }
  
  .alert {
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .alert-success {
    background-color: #dcfce7;
    color: var(--success-color);
    border: 1px solid #86efac;
  }
  
  .alert-error {
    background-color: #fee2e2;
    color: var(--error-color);
    border: 1px solid #fca5a5;
  }
  
  /* Form Sections */
  .form-section {
    margin-bottom: 2.5rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .form-section:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .form-section h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .form-section h2::before {
    content: '';
    width: 4px;
    height: 1.25rem;
    background: var(--primary-color);
    border-radius: 2px;
  }
  
  /* Form Styles */
  .signup-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    font-weight: 500;
    color: var(--text-primary);
    font-size: 0.875rem;
  }
  
  .form-group input,
  .form-group select {
    padding: 0.875rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    font-size: 0.875rem;
    transition: var(--transition);
    background: var(--background-light);
  }
  
  .form-group input:hover,
  .form-group select:hover {
    border-color: var(--primary-light);
  }
  
  .form-group input:focus,
  .form-group select:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
  
  .form-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.25rem;
  }
  
  /* Password Input */
  .password-input {
    position: relative;
  }
  
  .toggle-password {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 0.25rem;
    transition: var(--transition);
  }
  
  .toggle-password:hover {
    color: var(--primary-color);
  }
  
  /* Button Styles */
  .submit-button {
    background: var(--primary-color);
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    margin-top: 1rem;
  }
  
  .submit-button:hover {
    background: var(--primary-dark);
    transform: translateY(-1px);
    box-shadow: var(--shadow-md);
  }
  
  .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  /* Loader Styles */
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  }
  
  .spinner {
    width: 18px;
    height: 18px;
    border: 2px solid #ffffff;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Form Footer */
  .form-footer {
    text-align: center;
    margin-top: 2rem;
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  .login-link {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 500;
    transition: var(--transition);
  }
  
  .login-link:hover {
    color: var(--primary-dark);
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .signup-page {
      padding: 1.5rem;
    }
  
    .signup-content {
      padding: 2rem;
    }
  
    .form-row {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  
    .logo {
      font-size: 2rem;
    }
  
    .signup-header h1 {
      font-size: 1.75rem;
    }
  }
  
  @media (max-width: 480px) {
    .signup-page {
      padding: 1rem;
    }
  
    .signup-content {
      padding: 1.5rem;
    }
  
    .form-group input,
    .form-group select {
      padding: 0.75rem 0.875rem;
    }
  
    .submit-button {
      padding: 0.875rem;
    }
  }
  
  /* Print Styles */
  @media print {
    .signup-container {
      box-shadow: none;
      border: none;
    }
  
    .submit-button {
      display: none;
    }
  
    .signup-header {
      color: var(--text-primary);
    }
  
    .logo-accent {
      color: var(--primary-color);
    }
  }